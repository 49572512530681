.PolicyImg{
    text-align: center;
    margin-bottom: 80px;
}
.PolicyContent{
    margin-bottom: 50px;
}

.PolicyContent h3{
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
    position: relative;
}

blockquote{
    background-color: #fafafa;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
}
blockquote p{
    margin: 0 auto !important;
    opacity: 1.9;
}
.PolicyContent p{
    color: #707070;
    font-size: 17px;
    font-weight: 600;
    margin: 0 auto;
    margin-bottom: 15px;

}
.PolicyContent ul li{
    margin-left: 15px;
}

@media (min-width: 1200px) and (max-width: 1349px) {
    .PolicyImg {
        margin-bottom: 40px;
    }

}   

@media (min-width: 992px) and (max-width: 1199px) {

    .PolicyImg {
        margin-bottom: 40px;
    }
    .PolicyContent h3 {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .PolicyContent p {
        font-size: 16px;
    }
    .PolicyContent li {
        font-size: 16px;
    }
    .PolicyContent {
        margin-bottom: 40px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .PolicyImg {
        margin-bottom: 40px;
    }
    .PolicyContent h3 {
        font-size: 21px;
        margin-bottom: 15px;
    }
    .PolicyContent p {
        font-size: 15px;
    }
    .PolicyContent li {
        font-size: 15px;
    }
    .PolicyContent {
        margin-bottom: 40px;
    }

}
@media (min-width: 576px) and (max-width: 767px) {
    .PolicyImg {
        margin-bottom: 35px;
    }
    .PolicyContent h3 {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .PolicyContent p {
        font-size: 15px;
    }
    .PolicyContent li {
        font-size: 15px;
    }

    .PolicyContent {
        margin-bottom: 35px;
    }
}

@media (min-width: 320px) and (max-width: 575px) {
    .PolicyImg {
        margin-bottom: 25px;
    }
    .PolicyContent h3 {
        font-size: 18px;
        margin-bottom: 12px;
    }
    .PolicyContent p {
        font-size: 14px;
        margin-left: 0;
    }
    .PolicyContent li {
        font-size: 14px;
    }
    .PolicyContent {
        margin-bottom: 25px;
    }
}