
  .cursor-dot, 
  .cursor-dot-outline{
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .cursor-dot{
    z-index:2;
    width: 8px;
    height: 8px;
    background-color: #fe4a55;
  } 
  .cursor-dot-outline{
    width: 40px;
    z-index: 2;
    height: 40px;
    background-color: transparent;
    border: 1px solid  #fe4a55;
    
  }