.EventGallery{
  cursor: pointer;
}
.Event-menu {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.Event-menu ul {
  margin: 0 auto;
}

.Event-list li {
  display: inline-block;
  margin: 0 30px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid transparent;
  border-bottom-color: transparent;
  transition: 0.3s ease;
  cursor: pointer;
}

.photo-list {
  list-style: none;
  margin: 40px 0;
}

.Event-list li:hover {
  color: #FE4A55;
  border-bottom-color: #FE4A55;
  transition: 0.5s ease;
}

.Event-text {
  font-weight: 700;
  font-size: 22px;
  color: black;
}

.photo-item .card-body {
  padding: 24px;
}

.photo-item {
  display: flex;
  /* margin: 15px auto; */
  margin: unset;
  width: 100%;
}
.photo-item img {
  display: flex;
  width: 100%;
}

.Event-text:hover {
  color: #FE4A55;
  transition: 0.5s ease;
}

.Event-Img-effect:hover {
  background-size: 120%;
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 1 !important;
}

img.img-fluid.mx-auto.Event-Img-effect.aos-init {
  opacity: 1;
}

.Event-body p {
  font-size: 16px;
  color: #707070;
  ;
}

.Event-content1 {
  display: inline-block;
}

.Event-content1 i {
  display: inline-block;
  color: #707070;
  vertical-align: middle;
  font-size: 18px;
  opacity: 0.7;
}

.Event-content1 p {
  display: inline-block;
  font-size: 16px;
  text-transform: capitalize;
  margin: 0 auto;
  margin-left: 10px;
  vertical-align: middle;
  font-weight: 600;
  color: #FE4A55;
}

.Event-content2 {
  float: right;
}

.Event-content2 i {
  display: inline-block;
  color: #FE4A55;
  vertical-align: middle;
  font-size: 18px;
}

.Event-content2 p {
  display: inline-block;
  font-size: 16px;
  text-transform: capitalize;
  margin: 0 auto;
  margin-left: 10px;
  vertical-align: middle;
  font-weight: 600;
  cursor: pointer;
}

.Event-content2 p:hover {
  color: #FE4A55;
  transition: 0.5s ease;
}


/* Responsive */

@media (min-width: 1200px) and (max-width: 1349px) {
  .Event-list li {
    font-size: 18px;
  }

  .photo-item .card-body {
    padding: 22px;
  }

  .Event-text {
    font-size: 19px;
  }

  .Event-body p {
    font-size: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .Event-list li {
    font-size: 17px;
  }

  .Event-text {
    font-size: 17px;
  }

  .photo-item .card-body {
    padding: 15px;
  }

  .Event-body p {
    font-size: 15px;
  }
  .Event-content1 i{
    font-size: 15px;
  }
  .Event-content2 i {
    font-size: 15px;
  }


}

@media (min-width: 768px) and (max-width: 991px) {
  .Event-menu {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .Event-list li {
    font-size: 17px;
  }

  .photo-list {
    margin: 0px 0;
  }

  .photo-item .card-body {
    padding: 12px;
  }

  .Event-text {
    font-size: 18px;
  }

  .Event-body p {
    font-size: 13px;
  }

  .Event-content1 p {
    font-size: 12px;
    margin-left: 5px;
  }

  .Event-content1 i {
    font-size: 13px;
  }

  .Event-content2 p {
    font-size: 13px;
  }

  .Event-content2 i {
    font-size: 13px;
  }


}

@media (min-width: 576px) and (max-width: 767px) {
  .Event-menu {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .Event-list li {
    font-size: 14px;
  }

  .photo-list {
    margin: 0px 0;
  }

  .photo-item .card-body {
    padding: 12px;
  }

  .Event-text {
    font-size: 18px;
  }

  .Event-body p {
    font-size: 13px;
  }

  .Event-content1 p {
    font-size: 12px;
    margin-left: 5px;
  }

  .Event-content1 i {
    font-size: 13px;
  }

  .Event-content2 p {
    font-size: 13px;
  }

  .Event-content2 i {
    font-size: 13px;
  }


}

@media (min-width: 320px) and (max-width: 575px) {
  .Event-menu {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .Event-list li {
    margin: 0 5px;
    font-size: 13px;
  }

  .photo-list {
    margin: 0px 0;
  }

  .photo-item .card-body {
    padding: 12px;
  }

  .Event-text {
    font-size: 18px;
  }

  .Event-body p {
    font-size: 13px;
  }

  .Event-content1 p {
    font-size: 12px;
    margin-left: 5px;
  }

  .Event-content1 i {
    font-size: 13px;
  }

  .Event-content2 p {
    font-size: 13px;
  }

  .Event-content2 i {
    font-size: 13px;
  }


}