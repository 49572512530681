.Blog-card{
    margin-bottom: 24px;
    cursor: pointer;
}

.Blog-card .card-title{
    font-size: 22px;
    font-weight: 800;
}
.Blog-card p{
    font-size: 16px;
    font-weight: 500;
    color: #707070;
}
.Blog-img :hover .Blog-hover {
	opacity: 1;
	visibility: visible;
	z-index: 1;
	transform: scale(1);
	-webkit-transform: scale(0.9,0.9);
  }
.Blog-icon{
	position: relative;
	margin-bottom: 0;
	overflow: hidden;
  }
  
  .Blog-hover{
	opacity: 0;
	visibility: hidden;
	z-index: 0;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transition: 0.4s ease;
	transform: scale(1);
	-webkit-transform: scale(0.8);
	background-color: rgba(255, 255, 255, 0.4);
	padding: 10px;
	border-radius: 0;
  }
  
  .Blog-icon-box{
	position: absolute;
	left: 40px;
	right: 0;
	text-align: center;
	top: 45%;
  }
  
  .Blog-hover .Bicon {
	border-radius: 0;
	transform: translate(-50%, -50%);
	padding: 12px 12px;
	display: inline-block;
	line-height: 0px;
	background-color: #FE4A55;
    border-radius: 5px;
	color: #fff;
	float: none;
  }
  .Blog-hover .Bicon a{
      color: white;
  }
  .Blog-hover .Bicon:hover {
	background-color: #000;
	color: #fff;
  }

/* Responive */

@media (min-width: 992px) and (max-width: 1199px) {
    .Blog-card .card-title {
        font-size: 20px;
    }
    .Blog-card p {
        font-size: 15px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    
    .Blog-card .card-title {
        font-size: 20px;
    }
    .Blog-card p {
        font-size: 15px;
    }
    
}
@media (min-width: 576px) and (max-width: 767px) {
   
    .Blog-card .card-title {
        font-size: 17px;
    }
    .Blog-card p {
        font-size: 13px;
    }

}  
@media (min-width: 320px) and (max-width: 575px) {
    .Blog-card .card-title {
        font-size: 16px;
    }
    .Blog-card p {
        font-size: 12px;
    }
  }